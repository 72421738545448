import back1 from './../assets/back1-min.jpg'
const statements = [
  {
    key: 0,
    title:
      'Om du fick leva om ditt liv, hade du valt att göra saker annorlunda då?',
    message:
      'Det händer säkert att du ångrar vissa val i livet och att du önskar att du hade gjort saker på ett annat sätt. Men låt inte rädslan för att göra fel paralysera dig till att inte våga fatta några beslut alls. Den ena grejen leder till den andra och i slutändan blir det oftast ganska bra. ',
    bgimg: back1,
    slug: 'gora-annorlunda',
    event:
      'Grönstedts påminner om att inte låta rädslan för att göra fel paralysera dig.'
  },
  {
    key: 1,
    title: 'Vad betyder det att leva livet fullt ut?',
    message:
      'Först och främst, ta tillvara på det du har istället för att gräma dig över det du inte har. Lita på din intuition, fortsätt lär dig nya saker, utgå ifrån att du kommer lyckas och våga förändra. Då ska du se att du känner livet i dig.',
    bgimg: back1,
    slug: 'leva-livet-fullt-ut',
    event: 'Grönstedts påminner om att leva livet fullt ut.'
  },
  {
    key: 2,
    title:
      'Har du någon outnyttjad potential? Och vart skulle den kunna ta dig?',
    message:
      'Alla är bra på något. Men bara vissa har lyckats ta tillvara på sin talang fullt ut. Att uppnå sin fulla potential inom vad det än är, är mer tidskrävande är vad du kanske tror. Det krävs planering, tid och ansträngning. Lika bra att börja på en gång, så vad är din grej?',
    bgimg: back1,
    slug: 'outnyttjad-potential',
    event:
      'Grönstedts påminner om att ta till vara på din outnyttjade potential.'
  },
  {
    key: 3,
    title: 'Finns det något som håller dig tillbaka från att leva din passion?',
    message:
      'Drömmer du om att resa jorden runt, köp en resväska. Fantiserar du om att skriva en bok, öppna ett word-dokument, önskar du att du kunde springa ett maraton - sätt på dig löparskorna! Det är bara du och dina egna hjärnspöken som håller dig tillbaka.',
    bgimg: back1,
    slug: 'leva-din-passion',
    event:
      'Grönstedts påminner om att det är dina egna hjärnspöken som håller dig tillbaka.'
  },
  {
    key: 4,
    title: 'Vad skulle du göra om du visste att du inte kan misslyckas?',
    message:
      'Börja med att se tillbaka på dina gamla misslyckanden som bra erfarenheter, så hamnar misslyckandet plötsligt i en helt annan dager. Acceptera att saker ibland blir fel (det händer alla), lär dig något nytt och gå vidare. Gamla misstag kan inte göras ogjorda, men de kan påverka är hur du hanterar saker nu och i framtiden.',
    bgimg: back1,
    slug: 'leva-din-passion',
    event:
      'Grönstedts påminner om acceptera att saker ibland blir fel, lär dig något nytt och gå vidare.'
  },
  {
    key: 5,
    title: 'Vad är framgång för dig?',
    message:
      'Den vanligaste anledningen till människor inte blir framgångsrika är att de ger upp för tidigt. Det tar helt enkelt tid att lyckas (för de flesta). Så börja med att bestämma dig för vad du vill uppnå. Förbered dig sen på ett maraton, lär av andra på vägen och våga ta hjälp.',
    bgimg: back1,
    slug: 'framgang',
    event:
      'Grönstedts påminner om att den vanligaste anledningen till att människor inte blir framgångsrika, är för att de ger upp för tidigt.'
  },
  {
    key: 6,
    title: 'Vad skulle du säga till ditt 14 åriga jag?',
    message:
      'Det är inte helt dumt att rannsaka sig själv ibland och fundera över beslut man har fattat, saker man har sagt och gjort – men också sådant som man inte sa eller gjorde. Kom ihåg då att du lär dig av allt du går igenom, både det som är bra och det som är dåligt.',
    bgimg: back1,
    slug: '14-ariga-jag',
    event:
      'Grönstedts påminner om att du lär dig av allt du går igenom, både de som är bra och de som är dåligt.'
  },
  {
    key: 7,
    title: 'Vad vill du göra mer av i livet?',
    message:
      'När hade du senast en helt ledig eftermiddag eller helg utan massa måsten eller planer? Har du tänkt på att det kanske är just de där planlösa stunderna i livet som är de bästa. Och att det därför är kan vara en bra idé och att skapa plats för fler pauser i livet. Eller?',
    bgimg: back1,
    slug: 'mer-av-livet',
    event:
      'Grönstedts påminner om att det kan vara en bra idé och att skapa plats för fler pauser i livet'
  },
  {
    key: 8,
    title: 'Vilken är din största utmaning just nu?',
    message: '',
    bgimg: back1,
    slug: 'utmaning'
  },
  {
    key: 9,
    title: 'Hur ser en perfekt dag ut?',
    message: '',
    bgimg: back1,
    slug: 'perfekt-dag'
  },
  {
    key: 10,
    title: 'Hur skulle du skriva boken om dig själv?',
    message: '',
    bgimg: back1,
    slug: 'dig-sjalv'
  },
  {
    key: 11,
    title: 'Vilken skulle vara din drömgäst på en middag?',
    message: '',
    bgimg: back1,
    slug: 'dromgast'
  },
  {
    key: 12,
    title: 'Vad är det finaste man kan göra för en annan människa?',
    message: '',
    bgimg: back1,
    slug: 'finaste-man-kan-gora'
  },
  {
    key: 13,
    title: 'Vad hindrar dig från att fullt ut vara den personen du vill vara?',
    message: '',
    bgimg: back1,
    slug: 'hindrar-dig'
  },
  {
    key: 14,
    title: 'Vad i ditt liv är du mest tacksam för?',
    message: '',
    bgimg: back1,
    slug: 'tacksam-for'
  }
]

export { statements }
