function setParams(slug) {
  // window.location.pathname = '/' + slug
  window.history.pushState({}, '', '/' + slug)
  return true
}

function getParams(location) {
  return location.pathname.split('/').reverse()[0]
}

export { setParams, getParams }
