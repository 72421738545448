import React from 'react'

function Start({ bgimg, renderRandom, waiting }) {
  return (
    <div className="content start">
      <h1>Time to reflect</h1>
      <p>
        Hur ofta tar du dig tid för att stanna upp och fundera över de små och
        stora frågorna i livet? Förmodligen alldeles för sällan. Därför vill vi
        nu uppmuntra till en stunds reflektion. Allt du behöver göra är att
        samla dina vänner och börja.... prata. Gärna med hjälp av våra frågor
        här. Kanske kommer du fram till att det är dags att göra stora
        förändringar i livet. Kanske inser du att allt är precis som du alltid
        drömt om. Oavsett så kommer ni garanterat få ett trevligt häng. Så,
        klara, färdiga, tänk!{' '}
      </p>
    </div>
  )
}

export default Start
