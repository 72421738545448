import React, { useState, useEffect } from 'react'
import './App.scss'
import { statements } from './../data/strings.js'
import RandomStatement from './RandomStatement.js'
import Start from './Start.js'
import logo from './../assets/gronstedts-logga1.svg'
import { getParams, setParams } from './../utils/index.js'
import back1 from './../assets/back1-min.jpg'
import { get } from 'lodash'

function App() {
  const bgCount = 3
  const [random, setRandom] = useState(999)
  const [waiting, setWaiting] = useState(true)
  const [atBg, setAtbg] = useState(0)

  const renderSpecific = slug => {
    const indexOfSlug = statements.find(a => a.slug === slug)
    if (indexOfSlug) {
      setTimeout(() => {
        setRandom(parseInt(indexOfSlug.key))
        setTimeout(() => {
          setWaiting(false)
        }, 1200)
      }, 1200)
    } else {
      setTimeout(() => {
        setWaiting(false)
      }, 1200)
    }
  }
  useEffect(() => {
    const slug = getParams(window.location)
    renderSpecific(slug)
  }, [])

  const updateBg = () => {
    let newCount = atBg + 1
    if (newCount >= bgCount) {
      newCount = 0
    }
    setAtbg(newCount)
  }

  const renderRandom = () => {
    setWaiting(true)
    let rand = Math.floor(Math.random() * statements.length)
    if (rand === random) {
      if (rand === statements.length - 1) {
        rand--
      } else {
        rand++
      }
    }
    setTimeout(() => {
      setRandom(rand)
      setParams(get(statements, '[' + rand + '].slug', ''))
      updateBg()
      setTimeout(() => {
        setWaiting(false)
      }, 1200)
    }, 1200)
  }
  return (
    <div
      className="App"
      onClick={() => {
        renderRandom()
      }}
    >
      <div className="logotype">
        <img src={logo} alt="Grönstedt" />
      </div>
      <header
        className={'App-header' + (waiting ? ' waiting' : '')}
        style={{ backgroundImage: 'url(' + back1 + ')' }}
      >
        {random !== 999 ? (
          <RandomStatement
            renderRandom={renderRandom}
            statement={statements[random]}
            waiting={waiting}
          />
        ) : (
          <Start renderRandom={renderRandom} waiting={waiting} />
        )}
        <div className="next-button">Tryck på skärmen</div>
        <div className="bgs-container">
          <div className={'bg-0' + (atBg === 0 ? ' showing' : '')} />
          <div className={'bg-1' + (atBg === 1 ? ' showing' : '')} />
          <div className={'bg-2' + (atBg === 2 ? ' showing' : '')} />
        </div>
      </header>
    </div>
  )
}

export default App
