import React from 'react'
import AddToCalendar from 'react-add-to-calendar'
import moment from 'moment'

function RandomStatement({ statement, renderRandom, waiting }) {
  const today = moment()
    .add(30, 'days')
    .format()
  const { title, message, event } = statement
  return (
    <div className="content">
      <h1>{title}</h1>
      {message && message !== '' ? <p>{message}</p> : ''}
      {event ? (
        <div className="add-event" onClick={e => e.stopPropagation()}>
          Vill du att Grönstedts påminner dig om detta? <br />
          <AddToCalendar
            event={{
              title: event,
              description: message || title,
              location: 'Cognac, Frankrike',
              startTime: today,
              endTime: today,
              recurrenceRule: {
                frequency: 'monthly'
              }
            }}
            buttonTemplate={{ textOnly: 'none' }}
            buttonLabel="Aktivera kalenderpåminnelse här!"
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default RandomStatement
